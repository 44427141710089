<template>
  <span
    class="event-indicator"
    :style="{ '--calendar-event-type-color': mapIdToColor(id) }"
  ></span>
</template>

<script>
  import { EVENTS_LEGEND_COLORS } from '../../utils/constants.js';

  export default {
    name: 'EventIndicator',

    props: {
      id: {
        type: [Number, String, null],
        default: null
      }
    },

    methods: {
      mapIdToColor(id) {
        return EVENTS_LEGEND_COLORS[id];
      }
    }
  }
</script>

<style lang="scss" scoped>
  .event-indicator {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--calendar-event-type-color, #000);
  }
</style>
