<template>
  <div class="filter-block-ves-events">
    <div class="filter-block-ves-events__item">
      <p class="label-field">Субъект РФ</p>
      <SelectMultiComponent
        v-model="filter.RegionRfId"
        :options="regions"
        placeholder="Выберите субъект"
        modifier="rectangular"
        valueProp="id"
        track-by="name"
        label="name"
      />
    </div>
    <div class="filter-block-ves-events__item">
      <p class="label-field">Сортировка по дате проведения</p>
      <SelectMultiComponent
        v-model="filter.IsIncreasingOrder"
        :options="optionsDateSort"
        placeholder="По убыванию"
        modifier="rectangular"
        valueProp="id"
        track-by="text"
        label="text"
      />
    </div>
    <div class="filter-block-ves-events__item">
      <p class="label-field">Дата/период</p>
      <DateComponent
        v-model="filter.StartDate"
        placeholder="от"
        modClass="redesigned popover-left"
        :dateIcon="true"
        :clearable="false"
        :upperLimit="upperLimitFrom"
      />
      <DateComponent
        v-model="filter.EndDate"
        placeholder="до"
        modClass="redesigned"
        :dateIcon="true"
        :clearable="false"
        :lowerLimit="lowerLimitTo"
      />
    </div>
    <div class="filter-block-ves-events__item">
      <p class="label-field">Страна-партнер</p>
      <SelectMultiComponent
        v-model="filter.PartnerCountryId"
        :options="partnerCountryList"
        placeholder="Выберите страну-партнера"
        modifier="rectangular"
        valueProp="id"
        track-by="name"
        label="name"
      />
    </div>
    <div class="filter-block-ves-events__item">
      <ButtonComponent mod="gradient-bg" :disabled="getDisabledButton" @click="searchFilter">Показать</ButtonComponent>
      <ButtonComponent :disabled="getDisabledButton" @click="resetFilter">Сбросить</ButtonComponent>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';

  import DateComponent from '@/common/components/dateComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import removeEmptyValuesFromObject from '@/common/mixins/removeEmptyValuesFromObject';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';
  import store from '@/store/index';

  export default {
    name: 'FilterBlock',
    emits: ['searchFilter'],
    mixins: [removeEmptyValuesFromObject],
    components: {
      DateComponent,
      ButtonComponent,
      SelectMultiComponent,
    },
    props: {
      regionRfId: {
        type: String,
        default: '',
      },
      isSubjectRfRole: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        filter: {
          RegionRfId: '',
          IsIncreasingOrder: '',
          StartDate: '',
          EndDate: '',
          PartnerCountryId: '',
        },
        resetData: false,
        optionsDateSort: Constants.optionsDescendingAscending,
        regions: [],
      };
    },
    created() {
      this.getDirectives();
      this.filter.RegionRfId = this.regionRfId;
    },
    computed: {
      ...mapGetters({
        regionsList: DIRECTIVES_GETTERS_NAME.getRegionsSearchIdList,
        partnerCountryList: DIRECTIVES_GETTERS_NAME.getPartnerCountrySearchIdList,
      }),

      getDisabledButton() {
        return !(
          this.filter.RegionRfId ||
          this.filter.IsIncreasingOrder ||
          this.filter.StartDate ||
          this.filter.EndDate ||
          this.filter.PartnerCountryId
        );
      },
      getFilter() {
        return this.objectFormatter(this.filter);
      },
      upperLimitFrom() {
        if (this.filter.EndDate) {
          return moment(this.filter.EndDate, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return Constants.dateUpperLimit;
      },
      lowerLimitTo() {
        if (this.filter.StartDate) {
          return moment(this.filter.StartDate, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return Constants.dateLowerLimit;
      },
    },
    methods: {
      ...mapActions({
        getRegionsSearchId: DIRECTIVES_ACTIONS_NAME.getRegionsSearchId,
        getPartnerCountrySearchId: DIRECTIVES_ACTIONS_NAME.getPartnerCountrySearchId,
      }),

      async getDirectives() {
        const data = await this.getRegionsSearchId();
        this.getPartnerCountrySearchId();
        this.regions = data.data.items;

        // Для роли "Субъект РФ" доступен только его субъект
        if (this.isSubjectRfRole) {
          const userRegionRfIds = store._state?.data?.auth?.oidc?.profile?.employee.regionRfIds;
          this.regions = this.regions.filter(({ id }) => userRegionRfIds?.includes(id));
          this.searchFilter();
        }
      },

      resetFilter() {
        this.filter.RegionRfId = '';
        this.filter.IsIncreasingOrder = '';
        this.filter.StartDate = '';
        this.filter.EndDate = '';
        this.filter.PartnerCountryId = '';
        // Для роли Субъект РФ проставляем его субъект, чтобы не было возможности посмотреть другие встречи
        if (this.isSubjectRfRole) {
          this.filter.RegionRfId = this.regionRfId;
        }
        if (this.resetData) {
          this.resetData = false;
          this.$emit('searchFilter', this.getFilter);
        }
      },
      searchFilter() {
        this.resetData = true;
        this.$emit('searchFilter', this.getFilter);
      },
    },

    watch: {
      'filter.PartnerCountryId'() {
        this.getRegionsSearchId({ CountryIds: [this.filter.PartnerCountryId] });
      },
      'filter.RegionRfId'() {
        this.getPartnerCountrySearchId({ RegionsIds: [this.filter.RegionRfId] });
        this.$router.push({ query: { regionId: this.filter.RegionRfId } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .filter-block-ves-events {
    display: grid;
    align-items: end;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px 16px;
    margin-bottom: 40px;

    &__item {
      gap: 0 8px;
      flex-wrap: wrap;
      display: flex;

      & > p {
        width: 100%;
      }
    }

    @media (max-width: $desktop) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $laptop-2) {
      grid-template-columns: 100%;
    }
  }
</style>
