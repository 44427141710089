<template>
  <details class="events-group">
    <summary class="events-group__header">
      <EventsIndicator :id="statusId" />
      <span>{{ getGroupNameById }} ({{ list.length }})</span>
      <IconComponent name="arrowCurrentColor" class="events-group__icon" />
    </summary>
    <div class="events-group__body">
      <ul class="events-group__list">
        <li v-for="item in list" :key="item.id">
          <RouterLink
            class="events-group__link"
            :to="{ name: 'EventsCardVES', params: { id: item.id } }"
          >
            {{ item.name || item.description }}
          </RouterLink>
        </li>
      </ul>
    </div>
  </details>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  import { EVENTS_LEGEND_TITLES } from '../../utils/constants.js';
  import EventsIndicator from './EventIndicator.vue';

  export default {
    name: 'EventsGroup',

    components: {
      EventsIndicator,
      IconComponent
    },

    props: {
      statusId: EventsIndicator.props.id,
      list: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      getGroupNameById() {
        return EVENTS_LEGEND_TITLES[this.statusId];
      }
    }
  }
</script>

<style lang="scss" scoped>
  .events-group {}

  .events-group__header {
    font: $fira-16-M;
    list-style: '';
    display: flex;
    align-items: center;
    gap: 8px;

    &::-webkit-details-marker {
      display:none;
    }

    &::marker {
      display: none;
    }
  }

  .events-group__body {
    margin-top: 8px;
  }

  .events-group__icon {
    width: 24px;
    height: 24px;
    transition: 0.2s;
  }

  .events-group[open] .events-group__icon {
    transform: rotate(0.5turn);
  }

  .events-group__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font: $fira-14;
  }

  .events-group__link {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
  }
</style>
