<template>
  <ul class="events-legend">
    <li class="events-legend__item" v-for="(value, key) in legendItems" :key="key">
      <EventIndicator class="events-legend__indicator" :id="key"></EventIndicator>
      <span class="events-legend__text">{{ value }}</span>
    </li>
  </ul>
</template>

<script>
  import { EVENTS_LEGEND_TEXT } from '../../utils/constants.js';
  import EventIndicator from './EventIndicator.vue';

  export default {
    name: 'EventsLegend',

    components: {
      EventIndicator
    },

    data() {
      return {
        legendItems: EVENTS_LEGEND_TEXT
      }
    }
  }
</script>

<style lang="scss" scoped>
  .events-legend {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    column-gap: 24px;
    row-gap: 16px;
  }

  .events-legend__item {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .events-legend__indicator {
    flex: 0 0 auto;
  }

  .events-legend__text {
    font: $fira-14;
  }
</style>
